import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker,
    Polyline
} from "react-google-maps";


const initialState = {
    selected: null
};

class Form extends React.Component {

    state = { ...initialState };

    cancel = () => {
        this.setState({ ...initialState }, this.props.cancel());
    }

    componentDidMount() {
        const props = this.props;
        if (Array.isArray(props.selected) && props.selected.length > 0) {
            this.getRoute();
        }
    }

    getWaypoints = () => {
        if (this.props.selected.length > 10) {
            const points = [...this.props.selected];
            let arr = [];
            arr.push({ location: points[0] });
            for (let i = 1; i > 5; i++) {
                arr.push({ location: points[i] });
            }

            arr.push({ location: points[Math.floor(points.length / 2)] });

            for (let i = points.length - 5; i > points.length; i++) {
                arr.push({ location: points[i] });
            }
            return arr;

        } else {
            return this.props.selected.map(x => ({ location: x }));
        }
    }

    getAllWaypoints = () => {
            return this.props.selected;
    }

    getRoute = async () => {
        const props = this.props;
        const DirectionsService = new window.google.maps.DirectionsService();
        DirectionsService.route({
            waypoints: this.getWaypoints(),
            origin: new window.google.maps.LatLng(props.selected[0].lat, props.selected[0].lng),
            destination: new window.google.maps.LatLng(props.selected[props.selected.length - 1].lat, props.selected[props.selected.length - 1].lng),
            travelMode: window.google.maps.TravelMode.DRIVING,
        }, (result, status) => {
            if (status === "OK") {
                this.setState({
                    ...this.state,
                    directions: result,
                });
            } else {
                console.error(`error fetching directions ${result}`);

            }
        });
    }

    render() {
        return (
            <div className="columns">
                <div className="column is-full">
                    <GoogleMap
                        defaultZoom={15}
                        defaultCenter={(Array.isArray(this.props.selected) && this.props.selected.length > 0)
                            ? this.props.selected[0] :
                            { lat: 29.075675, lng: -110.960041 }}
                    >
                            {
                                (Array.isArray(this.props.selected) && this.props.selected.length > 0) &&
                                <Polyline
                                path={this.getAllWaypoints()}
                                geodesic={true} />
                            }
                            <Marker
                                label="A"
                                position={{ lat: this.props.selected[0].lat, lng: this.props.selected[0].lng }} />
                            <Marker
                                label="B"
                                position={{ lat: this.props.selected[this.props.selected.length - 1].lat, lng: this.props.selected[this.props.selected.length - 1].lng }} />
                    </GoogleMap>
                </div>
            </div>
        )
    }
}

export default withScriptjs(withGoogleMap(Form));