import React from "react";
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LoginApi from "api/login";
import { toast } from "react-toastify";
import avatar from "assets/img/logo512.png";
import { SRLWrapper } from "simple-react-lightbox";
import CloseIcon from "@material-ui/icons/Close";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, Chip, CircularProgress  } from "@material-ui/core";
     import moment from 'moment';
     import ClientsApi from "../../api/Clients";
     import DriversApi from "../../api/Drivers";
     import InfiniteScroll from 'react-infinite-scroll-component';
     import Visibility from "@material-ui/icons/Visibility";
     import TransferList from "./transferList";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

const initialState = {
    conductor: null,
    firstLocations: null,
    selected: null,
    selectedFirst: null,
    showModal: false,
    totalDistance: 0,
    modalTitle: "",
    showAcceptModal: false,
    filterText: "",
    callback: null,
    confirmTitle: "",
    confirmMessage: "",
    selectedDrivers: [],
    allDrivers: [],
    loading: true,
    selected: {
        user: {
            email: '',
            password: '',
            confirmpassword: '',
            rol: 3
        },
        profile: {
            name: "",
            id: '',
            lastName: '',
            // img: null,
            phone: '',
            birthday: ''
        }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function UserProfile(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    ...initialState
});


React.useEffect(()=>{
    const el = document.getElementById("card-container-body");
    el.scrollTo(0, 0);
    getClients();
},[]);



const getClients = async (offset = 0) => {
    try {
        let st = { ...state };
        st.conductor = props.location.state;
        let responseDrivers = await DriversApi.getAll(offset);
        st.allDrivers = [...st.allDrivers, ...responseDrivers.data.drivers.map(x => ({
            label: `${x.name} ${x.lastName}`,
            value: x.idUser,
            email: x.email
        }))]; 

        for(var i = 1; i< responseDrivers.data.totalPages; i++){
            let ids = responseDrivers.data.drivers.map(x => x.idDriverProfile);
            offset = Math.max(...ids);
            responseDrivers = await DriversApi.getAll(offset);
            st.allDrivers = [...st.allDrivers, ...responseDrivers.data.drivers.map(x => ({
                label: `${x.name} ${x.lastName}`,
                value: x.idUser,
                email: x.email
            }))]; 
        }

        if(st.selectedDrivers.length == 0){  
            const responseClient = await DriversApi.getAllByClientAdmin(st.conductor.idUser);
            st.selectedDrivers = responseClient.data.map(x => ({
                label: `${x.profile.name} ${x.profile.lastName}`,
                value: x.profile.idUser,
                email: x.profile.email
            }));
        }     
        st.loading = false;
        setState(st);
    } catch (e) {
        toast.warn("Error al consultar conductores.");
    }
}

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>  
        <SRLWrapper>
          <Card profile>
            <CardAvatar profile>  
              <a href={state?.conductor?.imgUrl ? state.conductor.imgUrl : avatar} onClick={e => e.preventDefault()}>
                <img src={state?.conductor?.imgUrl ? state.conductor.imgUrl : avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>Nombre: {state?.conductor?.name} {state?.conductor?.lastName}</h4>
                <h4 className={classes.cardTitle}>Telefono: {state?.conductor?.phone}</h4>
                <h4 className={classes.cardTitle}>Correo: {state?.conductor?.email}</h4>
            </CardBody>
          </Card>          
          </SRLWrapper>

        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
              Asignar conductores
              </h4>
            </CardHeader>
            <CardBody style={{maxHeight: "70vh", overflowY: "scroll"}} id="cardContainer">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                 <TransferList allDrivers={state.allDrivers} selectedDrivers={state.selectedDrivers} idClient={state?.conductor?.idUser} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      
    </div>
  );
}


export default withRouter(UserProfile);