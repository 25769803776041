import React from "react";
import {useHistory} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Button,Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, ListItemAvatar, Avatar } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Visibility from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { toast } from "react-toastify";
import ClientsApi from "api/Drivers";
import logo from "assets/img/logo512.png";
import InfiniteScroll from 'react-infinite-scroll-component';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const initialState = {
    showModal: false,
    showAssignModal: false,
    currentPage: 0,
    pages: 0,
    data: [],
    modalTitle: "",
    selected: {
        user: {
            email: '',
            password: '',
            confirmpassword: '',
            rol: 3
        },
        profile: {
            name: "",
            id: '',
            lastName: '',
            // img: null,
            phone: '',
            birthday: ''
        }
    },
    showAcceptModal: false,
    filterText: ""
};
export default function TableList() {
    const classes = useStyles();
    const history = useHistory();
    const [state,setState] = React.useState({...initialState});
    const [openSaveDialog, setOpenSaveeDialog] = React.useState(false);

    React.useEffect(()=>{
        getClients(false);
    },[]);    

    React.useEffect(()=>{
        const el = document.getElementById("card-container-body");
        el.scrollTo(0, 0);
      },[]);
    const getClients = async (next = true) => {
        try {
            let clientes = [];
            let st = { ...state };
            let offset = 0;
            if (next && st.currentPage !== 0 && st.currentPage < st.pages) {
                const ids = st.data.map(x => x.idDriverProfile);
                offset = Math.max(...ids);
            }
            const response = await ClientsApi.getAllByClient(offset);
            if (next) {

                clientes = [...st.data, ...response.data];
            } else {
                st.currentPage = 0;
                clientes = [...response.data];
            }
            setState({
                ...st,
                data: [...clientes],
                currentPage: state.currentPage + 1,
                pages: response.data.totalPages
            });
        } catch (e) {
            toast.warn('No hay clientes.');
        }
    }

    const closeModal = () => {
        let st = {...state};
        st.showModal = false;
        st.showAssignModal = false;
        setState(st);
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const save = async (cliente) => {
        //VALIDATE DATA
        try {
            await ClientsApi.update(cliente.profile);
            getClients(false);
            toast.success("El cliente se guardó correctamente");
            closeModal();
        } catch (e) {
            toast.error("Ocurrió un error al guardar intente mas tarde.");
        }
    }


    const edit = (client) => {
        let st = {...state};
        st.modalTitle = "Editar";
        st.showModal = true;
        st.selected = {
            user: {
                idUser: client.idUser,
                email: client.email,
                password: '',
                confirmpassword: '',
                rol: 3
            },
            profile: {
                name: client.name,
                idDriverProfile: client.idDriverProfile,
                lastName: client.lastName,
                phone: client.phone
            }
        };
        setState(st);
    }

  return (
      <>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Listado de conductores.</h4>
            <p className={classes.cardCategoryWhite} style={{color: "white !important"}}>
            </p>
          </CardHeader>
          <CardBody id="card-container">
          <InfiniteScroll
                                scrollThreshold={0}
                                scrollableTarget="card-container-body"
                                dataLength={state.data.length} //This is important field to render the next data
                                next={getClients}
                                hasMore={state.currentPage < state.pages}
                                loader={<h4>Cargando...</h4>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Estas viendo todos los conductores</b>
                                    </p>
                                }>
              <List>
              {state.data && state.data.map((d, ix) => {
                    return (
                        <ListItem alignItems="center" justifyContent="center" key={d.profile.idDriverProfile}>
                            <ListItemText style={{maxWidth: 50}}>{1+ix}</ListItemText>
                            <ListItemAvatar>
                                <Avatar alt="" src={d.imgUrl ? d.imgUrl : logo} />
                            </ListItemAvatar>
                            <ListItemText style={{textAlign: "center", maxWidth: 220}}>{d.profile.name}</ListItemText>
                            <ListItemText style={{textAlign: "center", maxWidth: 120}}>{(new Date(Date.parse(d.profile.createdAt)).toLocaleDateString("es-US").split(" ")[0])}</ListItemText>
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <IconButton 
                                    onClick={(e) => {
                                        history.push(`/admin/conductores/detalle`, d.profile)
                                    }
                                    }>
                                        <Visibility />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })
                }
              </List>
              </InfiniteScroll>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>


      <Dialog open={state.showModal} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {
                  state.modalTitle
              }
            </Typography>
            <Button autoFocus color="inherit" onClick={()=> save({...state.selected})}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
        <List>
            <ListItem>
                <FormControlLabel
                disabled={state.selected?.user.idUser}
                value={state.selected?.user?.email}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    user: {
                        ...state.selected.user,
                        email: e.target.value
                    }
                }})}
                label="Correo:"
                labelPlacement="top"
                control={<TextField />}
                 />
            </ListItem>
            <ListItem>
                <FormControlLabel
                value={state.selected?.profile?.name}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    profile: {
                        ...state.selected.profile,
                        name: e.target.value
                    }
                }})}
                label="Nombre:"
                labelPlacement="top"
                control={<TextField />}
                 />
            </ListItem>
            <ListItem>
                <FormControlLabel
                value={state.selected?.profile?.lastName}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    profile: {
                        ...state.selected.profile,
                        lastName: e.target.value
                    }
                }})}
                label="Apellido:"
                labelPlacement="top"
                control={<TextField />}
                 />
            </ListItem>
            <ListItem>
                <FormControlLabel
                value={state.selected?.profile?.phone}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    profile: {
                        ...state.selected.profile,
                        phone: e.target.value
                    }
                }})}
                label="Telefono:"
                labelPlacement="top"
                control={<TextField />}
                 />
            </ListItem>
        </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
