import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { toast } from "react-toastify";
import ClientsApi from "../../api/Clients";
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  listItem:{  
    width: "100% !important",
    display: "flex"
  },
  list: {
    height: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((item) => !b.some(x=> x.value === item.value));
}

function intersection(a, b) {
  const filter = a.filter(item => b.some(x=> x.value === item.value));
  return filter;
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList({allDrivers, selectedDrivers, ...props}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([...not(allDrivers, selectedDrivers)]);
  const [right, setRight] = React.useState([...selectedDrivers]);

  const [leftChecked, setLeftChecked] = React.useState([]);
  const [rightChecked, setRightChecked] = React.useState([]);
  const [values, setValues] = React.useState({
    conductores: "",
    asignados: ""
  });


  
  React.useEffect(()=>{
    setLeft(not(allDrivers, selectedDrivers));
    setRight(selectedDrivers);
  },[allDrivers,selectedDrivers]);


  const handleToggle = (item) => () => {
    const currentIndex = checked.some(x => x.value === item.value);
    let newChecked = [...checked];

    if (!currentIndex) {
      newChecked.push(item);
    } else {
        newChecked = newChecked.filter(x => x.value !== item.value);
    }
    const leftChk = intersection(left, newChecked);
    const rightChk = intersection(right, newChecked);
    setLeftChecked([...leftChk]);
    setRightChecked([...rightChk]);
    setChecked(newChecked); 
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
        const leftChk = items.every(x => leftChecked.some(y => y.value ===  x.value));
        const rightChk = items.every(x => rightChecked.some(y => y.value ===  x.value));
    if (leftChk || rightChk) {
        setLeftChecked([]);
        setRightChecked([]);
        setChecked([]);
    } else {
        setLeftChecked([...intersection(items,left)]);
        setRightChecked([...intersection(items,right)]);
        setChecked(items);
    }
  };

  const handleCheckedRight = () => {
    const letfChk = [...leftChecked];
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setLeftChecked([]);
    saveAssign(letfChk);
  };

  const handleCheckedLeft = () => {
    const rightChk = [...rightChecked];
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setRightChecked([]);
    saveunAssign(rightChk);
  };

  
  const saveAssign = (drivers) => {
    drivers.forEach(async x => {
        try {
            await ClientsApi.assign({
                client: props.idClient,
                driver: x.value
            });
            toast.success("Se asigno el conductor: " + x.label);
        } catch (e) {
            toast.warn("No se pudo asignar el conductor: " + x.label);
        }
    });
}


const saveunAssign = (drivers) => {
  drivers.forEach(async x => {
      try {
          await ClientsApi.unassign({
              client: props.idClient,
              driver: x.value
          });
          toast.success("Se removio el conductor: " + x.label);
      } catch (e) {
          toast.warn("No se pudo remover el conductor: " + x.label);
      }
  });
}
  const customList = (title, items, filter) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'todos los conductores seleccionados' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionado(s)`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.filter(x =>{
          if(filter && filter.length > 0){
            return x.label.toLowerCase().includes(filter.toLowerCase()) || x.email.toLowerCase().includes(filter.toLowerCase());
          }
          return true;
        }).map((item) => {
          const labelId = `transfer-list-all-item-${item.value}-label`;

          return (
            <ListItem key={item.value} className={classes.listItem} role="listitem" button onClick={handleToggle(item)}>
              <ListItemIcon style={{maxWidth: 50}}>
                <Checkbox
                  checked={checked.findIndex(x => {
                      return x.value === item.value;
                    }) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.label} secondary={item.email} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };



  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>
        <TextField fullWidth placeholder="Filtrar" onChange={handleChange('conductores')}></TextField>
        {customList('Conductores', left, values.conductores)}
        </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <TextField fullWidth placeholder="Filtrar" onChange={handleChange('asignados')}></TextField>
        {customList('Conductores asignados', right, values.asignados)}
        </Grid>
    </Grid>
  );
}
