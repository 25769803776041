import { apiUrl } from "../constants";
var axios = require('axios');
var request = axios.create({
    baseURL: apiUrl,
    headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json' }
})
export default class CourtsApi {
    static getAll() {
        return request.get('/carUses');
    }

    static get(id) {
        return request.get(`/carUses/${id}`);
    }

    static create(data) {
        return request.post('/carUses', data);
    }

    static edit(data, id) {
        return request.put(`/carUses/${id}`, data);
    }

    static delete(id) {
        return request.delete(`carUses/${id}`);
    }
}