import { apiUrl } from "../constants";
var axios = require('axios');
var request = axios.create({
    baseURL: apiUrl
})
export default class LoginApi {
    static login(email, password) {
        return request.post('auth/login', {
            email: email,
            password: password
        });
    }
    // static getUser(id) {
    //     return request.get(`profile/${id}`);
    // }
    static changePassword(data) {
        return request.post(`auth/change-password`, data);
    }
}