import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AcceptModal = (props) => {
    
    return (
        <Dialog
        open={props.isActive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
                {props.modalTitle}
                </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.body}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button 
            onClick={() => props.cancel(false)}
            color="danger">
            Cancelar
            </Button>
            <Button 
            onClick={() => props.done(true)}
            color="primary" autoFocus>
            Aceptar
            </Button>
        </DialogActions>
        </Dialog>
    );
}
    

export default AcceptModal