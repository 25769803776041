import { apiUrl } from "../constants";
var axios = require('axios');
var request = axios.create({
    baseURL: apiUrl,
    headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json' }
})
export default class CaptainsApi {
    static getAll(offset = 0) {
        return request.get('/clients?offset=' + offset);
    }

    static get(id) {
        return request.get(`/clients/${id}`);
    }

    static create(data) {
        return request.post('/auth/register', data);
    }

    static edit(data) {
        return request.put(`/clients`, data);
    }

    static assign(data) {
        return request.put(`/clients/assignDriver`, data);
    }
    static unassign(data) {
        return request.put(`/clients/unAssignDriver`, data);
    }

    static delete(id) {
        return request.delete(`clients/remove?idClientProfile=${id}`);
    }
}