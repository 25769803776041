import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Button,Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { toast } from "react-toastify";
import CarTypeApi from "api/CarType";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  listItem:{
      justifyContent: "center"
  },
  textField: {
    width: 400, 
    alignContent: "center", 
    alignSelf: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const initialState = {
    showModal: false,
    data: [],
    modalTitle: "",
    selected: {
        name: ""
    },
    showAcceptModal: false,
    filterText: ""
};
export default function TableList() {
    const classes = useStyles();
    const [state,setState] = React.useState({...initialState});
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openSaveDialog, setOpenSaveeDialog] = React.useState(false);

    React.useEffect(()=>{
        const getCarTypes = async () => {
            try {
                const response = await CarTypeApi.getAll();
                setState({ ...state, data: response.data });
            } catch (e) {
                toast.warn('No hay clientes.');
            }
        }
        getCarTypes();
    },[]);
    
React.useEffect(()=>{
  const el = document.getElementById("card-container-body");
  el.scrollTo(0, 0);
},[]);
    const getCarTypes = async () => {
        try {
            const response = await CarTypeApi.getAll();
            setState({ ...state, data: response.data });
        } catch (e) {
            toast.warn('No hay información.');
        }
    }
    
    const remove = async (id) => {
        try {
            await CarTypeApi.delete(id);
            let st = { ...state };
            st.selected = null;
            styles.showAcceptModal = false;
            setState(st);
            getCarTypes();
            toast.success("Tipo vehiculo eliminado");
            setOpenDeleteDialog(false);
        } catch (e) {
            toast.error('Ocurrió un problema, intente más tarde');
        }
    }
  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  
  const edit = (model) => {
    let st = {...state};
    st.modalTitle = "Editar";
    st.showModal = true;
    st.selected = {
        name: model.name,
        idCarType: model.idCarType
    };
    setState(st);
    setOpenSaveeDialog(true);
}


const save = async (model) => {
    //VALIDATE DATA
    if (model.name.length == 0) {
        toast.error("Agrega un nombre.");
        return;
    }
    try {
        if (!model.hasOwnProperty("idCarType")) {
            await CarTypeApi.create(model);
            toast.success("El tipo de vehiculo se guardó correctamente");
        } else {
            await CarTypeApi.edit(model, model.idCarType);
            toast.success("El tipo de vehiculo se guardó correctamente");
        }   
    getCarTypes();
    setOpenSaveeDialog(false);
    } catch (e) {
        toast.error("Ocurrió un error al guardar intente mas tarde.");
    }
}
  
  const closeModal = () => {
    let st = {...state};
    st.selected = {
        name: ""
    };
    st.showModal = false;
    setState(st);
    setOpenSaveeDialog(false);
    }
  return (
      <>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Listado de tipos de vehículos.</h4>
            <p className={classes.cardCategoryWhite} style={{color: "white !important"}}>
                <Button
                    variant="contained"
                    size="large"
                    startIcon={<Add />}
                    onClick={()=>{
                        let st = {...state};
                        st.modalTitle = "Editar";
                        st.showModal = true;
                        st.selected = {
                            name: ""
                        };
                        setState(st);
                        setOpenSaveeDialog(true);
                    }}
                >
                    Nuevo tipo de vehículo
                </Button>
            </p>
          </CardHeader>
          <CardBody>
              <List>
              {state.data && state.data.map((d, ix) => {
                    return (
                        <ListItem key={d.idCarType}>
                            <ListItemText style={{maxWidth: 50}}>{1+ix}</ListItemText>
                            <ListItemText>{d.name}</ListItemText>
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <IconButton onClick={()=> edit(d)}>
                                        <Edit />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemIcon>
                                    <IconButton 
                                    onClick={(e) => {
                                        setState({
                                        ...state,
                                        selected: d,
                                        showAcceptModal: true
                                    });
                                    handleClickOpenDeleteDialog();
                                    }
                                    }>
                                        <Delete />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })
                }
              </List>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar tipo vehiculo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {`¿Quieres eliminar el tipo: \n ${state.selected?.name}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
                            let st = { ...state };
                            st.selected = null;
                            st.showAcceptModal = false;
                            setState(st);
                            handleCloseDeleteDialog();
                        }} color="primary">
            Cancelar
          </Button>
          <Button onClick={()=> remove(state.selected.idCarType)} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog fullWidth open={openSaveDialog} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {
                  state.selected?.hasOwnProperty("idCarType") ? "Editar" : "Crear"
              }
            </Typography>
            <Button autoFocus color="inherit" onClick={()=> save({...state.selected})}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <ListItem className={classes.listItem}>
                <FormControlLabel
                value={state.selected?.name}
                onChange={(e)=> setState({...state, selected: {...state.selected, name: e.target.value}})}
                label="Nombre:"
                labelPlacement="top"
                control={<TextField  className={classes.textField}/>}
                 />
            </ListItem>
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}
