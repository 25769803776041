import React from "react";
import {useHistory} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Button,Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, ListItemAvatar, Avatar } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { toast } from "react-toastify";
import ClientsApi from "api/Clients";
import logo from "assets/img/logo512.png";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  listItem:{
      justifyContent: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const initialState = {
    showModal: false,
    showAssignModal: false,
    currentPage: 0,
    pages: 0,
    data: [],
    modalTitle: "",
    selected: {
        user: {
            email: '',
            password: '',
            confirmpassword: '',
            rol: 3
        },
        profile: {
            name: "",
            id: '',
            lastName: '',
            // img: null,
            phone: '',
            birthday: ''
        }
    },
    showAcceptModal: false,
    filterText: ""
};
export default function TableList() {
    const classes = useStyles();
    const history = useHistory();
    const [state,setState] = React.useState({...initialState});
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openSaveDialog, setOpenSaveeDialog] = React.useState(false);

    React.useEffect(()=>{
        getClients(false);
    },[]);    

    React.useEffect(()=>{
        const el = document.getElementById("card-container-body");
        el.scrollTo(0, 0);
      },[]);
    const getClients = async (next = true) => {
        try {
            let clientes = [];
            let st = { ...state };
            let offset = 0;
            if (next && st.currentPage !== 0 && st.currentPage < st.pages) {
                const ids = st.data.map(x => x.idClientProfile);
                offset = Math.max(...ids);
            }
            const response = await ClientsApi.getAll(offset);
            if (next) {

                clientes = [...clientes, ...response.data.clients];
            } else {
                st.currentPage = 0;
                clientes = [...response.data.clients];
            }
            setState({
                ...st,
                data: [...clientes],
                currentPage: state.currentPage + 1,
                pages: response.data.totalPages
            });
        } catch (e) {
            toast.warn('No hay clientes.');
        }
    }

    const closeModal = () => {
        let st = {...state};
        st.showModal = false;
        st.showAssignModal = false;
        setState(st);
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const saveAssign = (drivers, idClient) => {
        drivers.forEach(async x => {
            try {
                await ClientsApi.assign({
                    client: idClient,
                    driver: x.value
                });
                toast.success("Se asigno el conductor: " + x.label);
            } catch (e) {
                toast.warn("No se pudo asignar el conductor: " + x.label);
            }
        });
    }

    const save = async (cliente) => {
        //VALIDATE DATA
        try {
            if (!cliente.profile?.hasOwnProperty("idClientProfile")) {
                if (!cliente.user.email && !validateEmail(cliente.user.email)) {
                    toast.error("Correo incorrecto.");
                    return;
                } else
                    if (cliente.user.password.length < 6) {
                        toast.error("Las contraseña debe de tener un mínimo de 6 caracteres.");
                        return;
                    } else if (cliente.user.password !== cliente.user.confirmpassword) {
                        toast.error("Las contraseñas deben de coincidir.");
                        return;
                    }
                await ClientsApi.create(cliente);
                getClients(false);
                toast.success("El cliente se guardó correctamente");
            } else {
                await ClientsApi.edit(cliente.profile);
                getClients(false);
                toast.success("El cliente se guardó correctamente");
            }
            closeModal();
        } catch (e) {
            toast.error("Ocurrió un error al guardar intente mas tarde.");
        }
    }

    const create = () => {
        let st = {...state}
        st.modalTitle = "Nuevo";
        st.showModal = true;
        st.selected = {...initialState.selected};
        setState(st);
    }

    const edit = (client) => {
        let st = {...state};
        st.modalTitle = "Editar";
        st.showModal = true;
        st.selected = {
            user: {
                idUser: client.idUser,
                email: client.email,
                password: '',
                confirmpassword: '',
                rol: 3
            },
            profile: {
                name: client.name,
                idClientProfile: client.idClientProfile,
                lastName: client.lastName,
                phone: client.phone
            }
        };
        setState(st);
    }

    const asignModal = (client) => {
        let st = state
        st.modalTitle = "Asignar conductor";
        st.showAssignModal = true;
        st.selected = {
            user: {
                idUser: client.idUser,
                email: client.email,
                password: '',
                confirmpassword: '',
                rol: 3
            },
            profile: {
                name: client.name,
                idClientProfile: client.idClientProfile,
                lastName: client.lastName,
                phone: client.phone
            }
        };
        setState(st);
    }

    const remove = async (id) => {
        try {
            await ClientsApi.delete(id);
            let st = { ...state };
            st.selected = {...initialState.selected};
            st.showAcceptModal = false;
            setState(st);
            getClients(false);
            toast.success("Cliente eliminado");
        } catch (e) {
            toast.error('Ocurrió un problema, intente más tarde');
        }
    }
  return (
      <>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Listado de clientes.</h4>
            <p className={classes.cardCategoryWhite} style={{color: "white !important"}}>
                <Button
                    variant="contained"
                    size="large"
                    startIcon={<Add />}
                    onClick={(e) => create()}
                >
                    Nuevo cliente
                </Button>
            </p>
          </CardHeader>
          <CardBody>
              <List>
              {state.data && state.data.map((d, ix) => {
                    return (
                        <ListItem key={d.idClientProfile}>
                            <ListItemText style={{maxWidth: 50}}>{1+ix}</ListItemText>
                            <ListItemAvatar>
                                <Avatar alt="" src={d.imgUrl ? d.imgUrl : logo} />
                            </ListItemAvatar>
                            <ListItemText>{d.name} {d.lastName}</ListItemText>
                            <ListItemText>{d.email}</ListItemText>
                            <ListItemText>{d.phone}</ListItemText>
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <IconButton onClick={()=> edit(d)}>
                                        <Edit />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemIcon>
                                    <IconButton 
                                    onClick={(e) => {
                                        setState({
                                        ...state,
                                        selected: {...d},
                                        showModal: false,
                                        showAcceptModal: true
                                    });
                                    }
                                    }>
                                        <Delete />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemIcon>
                                    <IconButton 
                                    onClick={(e) => {
                                        history.push(`/admin/clientes/detalle`, d)
                                    }
                                    }>
                                        <Visibility />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })
                }
              </List>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    {state.showAcceptModal && <Dialog
        open={state.showAcceptModal}
        onClose={() => {
            let st = { ...state };
            st.selected = null;
            st.showAcceptModal = false;
            setState(st);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar cliente"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {`¿Quieres eliminar el cliente: \n `}
          {state.selected?.name} {state.selected?.lastName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            let st = { ...state };
            st.selected = null;
            st.showAcceptModal = false;
            setState(st);
        }} color="primary">
            Cancelar
          </Button>
          <Button onClick={()=> remove(state?.selected?.idClientProfile)} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>}


      {state.showModal && <Dialog fullWidth open={state.showModal} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {
                  state.modalTitle
              }
            </Typography>
            <Button autoFocus color="inherit" onClick={()=> save({...state.selected})}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{verticalAlign: "center"}}>
        <List style={{width: "100%", display:"flex",
        flexDirection:"column", justifyContent: "center", alignContent: "center"}}>
            <ListItem className={classes.listItem} >
                <FormControlLabel
                fullWidth
                disabled={state.selected?.user.idUser}
                value={state.selected?.user?.email}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    user: {
                        ...state.selected.user,
                        email: e.target.value
                    }
                }})}
                label="Correo:"
                labelPlacement="top"
                control={<TextField
                    style={{width: 400, alignContent: "center", alignSelf: "center"}} />}
                 />
            </ListItem>
            <ListItem  className={classes.listItem}>
                <FormControlLabel
                value={state.selected?.user?.password}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    user: {
                        ...state.selected.user,
                        password: e.target.value
                    }
                }})}
                label="Contraseña:"
                labelPlacement="top"
                control={<TextField type="password"
                    style={{width: 400, alignContent: "center", alignSelf: "center"}}/>}
                 />
            </ListItem>
            <ListItem  className={classes.listItem}>
                <FormControlLabel
                value={state.selected?.user?.confirmpassword}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    user: {
                        ...state.selected.user,
                        confirmpassword: e.target.value
                    }
                }})}
                label="Confirmar Contraseña:"
                labelPlacement="top"
                control={<TextField type="password"
                    style={{width: 400, alignContent: "center", alignSelf: "center"}} />}
                 />
            </ListItem>
            <ListItem  className={classes.listItem}>
                <FormControlLabel
                value={state.selected?.profile?.name}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    profile: {
                        ...state.selected.profile,
                        name: e.target.value
                    }
                }})}
                label="Nombre:"
                labelPlacement="top"
                control={<TextField 
                    style={{width: 400, alignContent: "center", alignSelf: "center"}}/>}
                 />
            </ListItem>
            <ListItem  className={classes.listItem}>
                <FormControlLabel
                value={state.selected?.profile?.lastName}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    profile: {
                        ...state.selected.profile,
                        lastName: e.target.value
                    }
                }})}
                label="Apellido:"
                labelPlacement="top"
                control={<TextField 
                    style={{width: 400, alignContent: "center", alignSelf: "center"}} />}
                 />
            </ListItem>
            <ListItem className={classes.listItem} >
                <FormControlLabel
                value={state.selected?.profile?.phone}
                onChange={(e)=> setState({...state, selected: {
                    ...state.selected, 
                    profile: {
                        ...state.selected.profile,
                        phone: e.target.value
                    }
                }})}
                label="Telefono:"
                labelPlacement="top"
                control={<TextField 
                    style={{width: 400, alignContent: "center", alignSelf: "center"}} />}
                 />
            </ListItem>
        </List>
        </DialogContent>
      </Dialog>}
    </>
  );
}
