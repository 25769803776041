import { apiUrl } from "../constants";
var axios = require('axios');
var request = axios.create({
    baseURL: apiUrl,
    headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json' }
})
export default class DriverApi {

    static update(driver){
        return request.put("/profile/" + driver.user.idUser,driver.profile);
    }
    static getAll(offset = 0) {
        return request.get('/drivers/filter?offset=' + offset);
    }
    static getAllByClient() {
        return request.get(`/clients/${localStorage.getItem("id")}/drivers`);
    }
    static getAllActiveDrivers() {
        return request.get(`/drivers/active-drivers`);
    }
    static getAllNotificableDrivers() {
        return request.get(`/users/usernotificable`);
    }
    static sendNotificationDrivers(body) {
        return request.post(`/users/NotifyUsers`, body);
    }
    static getAllRouteByUserId(id, offset = 0) {
        return request.get(`/route/driver/${id}/routes?offset=${offset}`);
    }

    static getAllByClientAdmin(id) {
        return request.get(`/clients/${id}/drivers`);
    }

    static get(id) {
        return request.get(`/profiles/driver/${id}`);
    }

    static getFirstPoints(id) {
        return request.get(`/route/driver/${id}/test-routes`);
    }

    static activate(id) {
        return request.put(`/profiles/activate/${id}`);
    }

    static campaign(id) {
        return request.put(`/profiles/campaign/${id}`);
    }

    static delete(id) {
        return request.delete(`/users/${id}`);
    }
    
    static reactivate(id) {
        return request.put(`/users/${id}/reactivate`);
    }
}