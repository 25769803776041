import { apiUrl } from "../constants";
var axios = require('axios');
var request = axios.create({
    baseURL: apiUrl,
    headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json' }
})
export default class CarType {
    static getAll() {
        return request.get('/carTypes');
    }

    static get(id) {
        return request.get(`/carTypes/${id}`);
    }

    static create(data) {
        return request.post('/carTypes', data);
    }

    static edit(data, id) {
        return request.put(`/carTypes/${id}`, data);
    }

    static delete(id) {
        return request.delete(`carTypes/${id}`);
    }
}