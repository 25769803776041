import React from "react";
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LoginApi from "api/login";
import { toast } from "react-toastify";
import avatar from "assets/img/logo512.png";
import { SRLWrapper } from "simple-react-lightbox";
import CloseIcon from "@material-ui/icons/Close";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, Chip, CircularProgress  } from "@material-ui/core";
import moment from 'moment';
import DriversApi from "../../api/Drivers";
import ClientesForm from './form';
import ClientesFormFirst from './formFirstKm';
import ClientesFormCurrent from './currentLocationForm';
import InfiniteScroll from 'react-infinite-scroll-component';
import Visibility from "@material-ui/icons/Visibility";
import AcceptModal from "components/accept_modal/AcceptModal";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

const initialState = {
    conductor: null,
    firstLocations: null,
    selected: null,
    selectedFirst: null,
    showModal: false,
    totalDistance: 0,
    modalTitle: "",
    showAcceptModal: false,
    filterText: "",
    callback: null,
    confirmTitle: "",
    confirmMessage: ""
};

const isAdmin = () =>{
    return Number(localStorage.getItem("rol")) === 1;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function UserProfile(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    ...initialState
});

const [paginator, setPaginator] = React.useState({
    currentPage: 0,
    pages: 0,
    data: []});


React.useEffect(()=>{
    getRoutes(false);
    const el = document.getElementById("card-container-body");
    el.scrollTo(0, 0);
    getClients();
},[]);



const getClients = async (st = state) => {
    try {
        const response = await DriversApi.get(props.location.state.idUser);
        const responsePoints = await DriversApi.getFirstPoints(props.location.state.idUser);
        setState({ ...st, conductor: response.data, firstLocations: responsePoints.data, totalDistance: responsePoints.data.totalDistance });
    } catch (e) {
        toast.warn('No hay conductor.');
    }
}



const getRoutes = async (next = true) => {
    try {
        let clientes = [];
        let st = { ...paginator };
        let offset = 0;
        if (next && st.currentPage !== 0 && st.currentPage < st.pages) {
            const ids = st.data.map(x => x.route.idRoute);
            offset = Math.max(...ids);
        }
        const response = await DriversApi.getAllRouteByUserId(props.location.state.idUser, offset);
        if (next) {

            clientes = [...paginator.data, ...response.data.routes];
        } else {
            st.currentPage = 0;
            clientes = [...response.data.routes];
        }
        setPaginator({
            ...st,
            data: [...clientes],
            currentPage: st.currentPage + 1,
            pages: response.data.totalPages
        });
    } catch (e) {
        toast.warn('No hay rutas para este conductor.');
    }
}

const closeModal = () => {
    let st = state;
    st.selected = null;
    st.selectedFirst = null;
    st.showModal = false;
    setState(st);
}

const onDone = () => {
    switch (state.callback) {
        case "ACTIVE":
            active();
            return;
        case "CAMPAIGN":
            campaign();
            return;
        case "DELETE":
            remove();
            return;
        case "REACTIVATE":
            reactivate();
            return;
        default:
            toast.info("No se puede realizar accion.");
    }
}

const reactivate = async () =>{
    try {
        const response = await DriversApi.reactivate(props.location.state.idUser);
        let st = {...state};
        st.conductor.user.deletedAt = (st.conductor?.user?.deletedAt!=null)? null : "REACTIVATE";

        setState({ ...st, showAcceptModal: false });
        toast.success('Conductor dado de alta.');
    } catch (e) {
        toast.warn('Error al dar de alta.');
    }
}

const active = async () => {
    try {
        const response = await DriversApi.activate(props.location.state.idUser);
        let st = { ...state, showAcceptModal: false };
        setState(st);
        getClients(st);
        toast.success('Conductor activo.');
    } catch (e) {
        toast.warn('Error al activar.');
    }
}

const campaign = async () => {
    try {
        const response = await DriversApi.campaign(props.location.state.idUser);
        let st = { ...state, showAcceptModal: false };
        setState(st);
        getClients(st);
        toast.success('Conductor en campaña.');
    } catch (e) {
        toast.warn('Error al activar campaña.');
    }
}

const remove = async () => {
    try {
        const response = await DriversApi.delete(props.location.state.idUser);
        let st = {...state};
        st.conductor.user.deletedAt = (st.conductor?.user?.deletedAt!=null)? null : "REACTIVATE";

        setState({ ...st, showAcceptModal: false });
        toast.success('Conductor desactivado.');
    } catch (e) {
        toast.warn('Error al desactivar conductor.');
    }
}

const getTagByStatus = () => {
    if(state.conductor?.user?.deletedAt !== null){
        return (<Chip label="Inactivo"></Chip>)
    }
    switch (state.conductor?.profile?.status) {
        case 0:
            return (<Chip label="Inactivo"></Chip>)
        case 1:
            return (<Chip label="Nuevo"></Chip>)
        case 2:
            return (<Chip label="Activo"></Chip>)
        case 3:
            return (<Chip label="En Campaña"></Chip>)
        default:
            return (<CircularProgress></CircularProgress>)
    }
}

const durationPrint = (data) => {
    const final = moment(data.route.finishedAt);
    const start = moment(data.route.createdAt);

    return `${moment.utc(final.diff(start)).format("HH:mm:ss")}`
}

const closeAcceptModal = () => {
    let st = { ...state };
    st.showAcceptModal = false;
    st.callback = null;
    setState(st);
}
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>  
        <SRLWrapper>
          <Card profile>
            <CardAvatar profile>  
              <a href={state?.conductor?.profile?.imgUrl ? state.conductor.profile.imgUrl : avatar} onClick={e => e.preventDefault()}>
                <img src={state?.conductor?.profile?.imgUrl ? state.conductor.profile.imgUrl : avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              {isAdmin() &&
                  <h4 className={classes.cardTitle}>
                  Fecha de registro: {(new Date(Date.parse(state?.conductor?.profile?.createdAt)).toLocaleDateString("es-US").split(" ")[0])}
                  </h4>
                  }
              <h4 className={classes.cardTitle}>Nombre: {state?.conductor?.profile?.name} {isAdmin() ? state?.conductor?.profile?.lastName : ""}</h4>
                {isAdmin() && <h4 className={classes.cardTitle}>Telefono: {state?.conductor?.profile?.phone}</h4>}
                {isAdmin() && <h4 className={classes.cardTitle}>Municipio: {state?.conductor?.profile?.city}</h4>}
                {isAdmin() && <h4 className={classes.cardTitle}>Estado: {state?.conductor?.profile?.state}</h4>}
                {isAdmin() && <h4 className={classes.cardTitle}>Correo: {state?.conductor?.user?.email}</h4>}
            </CardBody>
          </Card>
          <Card profile>
            <CardAvatar profile>    
              <a href={state?.conductor?.vehicle?.imgUrl ? state.conductor.vehicle.imgUrl : avatar} onClick={e => e.preventDefault()}>
                <img src={state?.conductor?.vehicle?.imgUrl ? state.conductor.vehicle.imgUrl : avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>Auto: {state?.conductor?.vehicle?.brand} {state?.conductor?.vehicle?.model}</h4>
                <h4 className={classes.cardTitle}>Color: {state?.conductor?.vehicle?.color}</h4>
                <h4 className={classes.cardTitle}>Año: {state?.conductor?.vehicle?.year}</h4>
                {isAdmin() && (<h4 className={classes.cardTitle}>Total Recorrido de prueba: {state.totalDistance} Km.</h4>) }
                <h4 className={classes.cardTitle}>
                    <Button color="primary" onClick={(e) => {
                        if (state.conductor?.profile?.isTracking) {
                            let st = { ...state };
                            st.selectedFirst = null;
                            st.selected = null;
                            st.modalTitle = `Ubicacion Actual`;
                            st.showModal = true;
                            setState(st);
                        } else {
                            toast.warn("El conductor no esta operando actualmente.");
                        }
                    }}>Ubicación Actual</Button>
                </h4>
                {isAdmin() && 
                <h4 className={classes.cardTitle}>
                    <Button color="primary" onClick={(e) => {
                        if (Array.isArray(state.firstLocations.data)) {
                            let st = { ...state };
                            st.selectedFirst = state.firstLocations.data;
                            st.modalTitle = `Primeros 100KM`;
                            st.showModal = true;
                            setState(st);
                        } else {
                            toast.warn("No se han registrado rutas.");
                        }
                    }}>Primeros 100km</Button>
                </h4>
                }
            </CardBody>
          </Card>              
          </SRLWrapper>

        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
              Conductor: {state?.conductor?.profile?.name} {state?.conductor?.profile?.lastName}
              </h4>
              <div className={classes.cardCategoryWhite}>
              Status: {getTagByStatus()}
              </div>
            </CardHeader>
            <CardBody style={{maxHeight: "55vh", overflowY: "scroll"}} id="cardContainer">
              <GridContainer>
                {isAdmin() && <GridItem xs={12} sm={6} md={4}>
                    <Button
                        color={"info"}
                        disabled={  [2].some(x=> x === state.conductor?.profile?.status)}
                        onClick={(e) => {
                            let st = { ...state };
                            st.callback = "ACTIVE";
                            st.confirmMessage = "Esta apunto de activar el coductor.";
                            st.confirmTitle = "Aviso de activacion";
                            st.showAcceptModal = true;
                            setState(st);
                        }}>
                Activar
            </Button>
                </GridItem>}
                {isAdmin() && <GridItem xs={12} sm={6} md={4}>
                <Button
                color={![3].find(x=> x === state.conductor?.profile?.status) ? "success" : "white"}
                    disabled={[3].find(x=> x === state.conductor?.profile?.status)}
                    onClick={(e) => {
                        let st = { ...state };
                        st.callback = "CAMPAIGN";
                        st.confirmMessage = "Esta apunto de activar la campaña del coductor.";
                        st.confirmTitle = "Aviso de activacion de campaña";
                        st.showAcceptModal = true;
                        setState(st);
                    }}>
                    Activar Campaña
                </Button>
                </GridItem>}
                {isAdmin() && <GridItem xs={12} sm={6} md={4}>
                <Button
                    color={((state.conductor?.user?.deletedAt!=null)? "success" : "danger")}
                    onClick={(e) => {
                        let st = { ...state };
                        st.callback = (st.conductor?.user?.deletedAt!=null)? "REACTIVATE" : "DELETE"
                        st.confirmMessage = (st.conductor?.user?.deletedAt!=null)? "Está a punto de dar de alta al conductor.":"Está apunto de dar de baja al coductor.";
                        st.confirmTitle = (st.conductor?.user?.deletedAt!=null)? "Aviso de alta del conductor" :"Aviso de dar de baja a coductor";
                        st.showAcceptModal = true;
                        setState(st);
                    }}>
                    {(state.conductor?.user?.deletedAt)? "Dar de alta":"Dar de baja"}
                </Button>
                </GridItem>}
                <GridItem xs={12} sm={12} md={12}>
                    
          <InfiniteScroll
                                scrollThreshold={0}
                                scrollableTarget="cardContainer"
                                dataLength={paginator.data.length} //This is important field to render the next data
                                next={getRoutes}
                                hasMore={paginator.currentPage < paginator.pages}
                                loader={<h4>Cargando...</h4>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>Estas viendo todas las rutas</b>
                                    </p>
                                }>
              <List>
              {paginator.data && paginator.data.map((d, ix) => {
                    return (
                        <ListItem key={d.route.idRoute}>
                            <ListItemText style={{maxWidth: 50}}>{1+ix}</ListItemText>
                            <ListItemText>{moment(d.route.createdAt).format("DD/MM/YYYY hh:mma")}</ListItemText>
                            <ListItemText>{durationPrint(d)}</ListItemText>
                            <ListItemText>{d.route?.distance ? `${d.route.distance?.toFixed(2)}km` : "0km"}</ListItemText>
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <IconButton 
                                    onClick={(e) => {
                                        let st = { ...state };
                                        st.selected = d.locations.map(x => ({ lat: x.latitude, lng: x.longitude }));
                                        st.modalTitle = `${d.route.distance.toFixed(2)}km: ${moment(d.route.createdAt).format("DD/MM/YYYY hh:mma")} - 
                                        ${moment(d.route.finishedAt).format("DD/MM/YYYY hh:mma")}`;
                                        st.showModal = true;
                                        setState(st);
                                    }}>
                                        <Visibility />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })
                }
              </List>
              </InfiniteScroll>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    <AcceptModal
        isActive={state.showAcceptModal}
        done={(t) => {
            onDone();
        }}
        cancel={() => closeAcceptModal()}
        modalTitle={`${state.confirmTitle}`}
        body={`${state.confirmMessage}`}
    />
      {state.showModal &&
                    <Dialog fullWidth open={state.showModal} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setState({...state, showModal: false})} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          {state.modalTitle}
                        </Typography>
                      </Toolbar>
                    </AppBar>
                        <DialogContent>
                            
                        {state.selected && <ClientesForm
                                selected={state.selected}
                                cancel={() => closeModal()}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj32XEEWbpWreSa6kiO3M4xeqAHj9j-rQ&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />}

                            {state.selectedFirst && <ClientesFormFirst
                                selected={state.selectedFirst}
                                cancel={() => closeModal()}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj32XEEWbpWreSa6kiO3M4xeqAHj9j-rQ&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />}

                            {(!state.selected && !state.selectedFirst) && <ClientesFormCurrent
                                cancel={() => closeModal()}
                                driverId={props.location.state.idUser}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj32XEEWbpWreSa6kiO3M4xeqAHj9j-rQ&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />}
                        </DialogContent>
                  </Dialog>
                }
      
    </div>
  );
}


export default withRouter(UserProfile);