import 'fontsource-roboto';
import React from 'react';
import { render } from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { createBrowserHistory } from "history";
import Login from '../src/components/login/Login';
import Footer from './components/Footer/Footer';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox'
import Admin from "layouts/Admin.js";

const hist = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { didLogin: false };
    }
    render() {
        return (
            <SimpleReactLightbox>
            <Router history={hist}>
                    <Admin/>
                    <Footer />
                    <ToastContainer />
            </Router>
    </SimpleReactLightbox>
        )
    }
}

const GenericNotFound = () => {
    return (<h2>Pagina no encontrada.</h2>)
}

const AppLogin = () => (
    <div className="App">
        <Login />
    </div>
)

if (localStorage.getItem("token")) {
    render(<HttpsRedirect><App/></HttpsRedirect>, document.getElementById('root'));
} else {
    render(<HttpsRedirect><AppLogin /></HttpsRedirect>, document.getElementById('root'));
}
