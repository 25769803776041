import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LoginApi from "api/login";
import { toast } from "react-toastify";
import avatar from "assets/img/logo512.png";
import Lock from "@material-ui/icons/Lock";
import CloseIcon from "@material-ui/icons/Close";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, Box } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  listItem:{
      justifyContent: "center"
  },
  textField: {
    width: 400, 
    alignContent: "center", 
    alignSelf: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function UserProfile() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    showModal: false,
    data: JSON.parse(localStorage.getItem("ProfileUser"))
});

React.useEffect(()=>{
  const el = document.getElementById("card-container-body");
  el.scrollTo(0, 0);
},[]);


const save = async (data) => {
    if (!data.hasOwnProperty("password") || data.password.length < 6) {
        toast.warn("La contraseña debe tener al menos 6 caracteres");
        return;
    }
    if (!data.hasOwnProperty("oldpassword") || data.oldpassword.length < 6) {
        toast.warn("La contraseña debe tener al menos 6 caracteres");
        return;
    }
    if (!data.hasOwnProperty("confirmpassword") || data.password !== data.confirmpassword) {
        toast.warn("La contraseña debe coincidir.");
        return;
    }
    try {
        await LoginApi.changePassword({
            password: data.oldpassword,
            newPassword: data.password,
            email: state.data.user.email
        });
        toast.success("Contraseña actualizada");
        setState({ ...state, showModal: false });
    } catch (e) {
        toast.warn("La contraseña no se pudo actualizar.");
    }
}
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Mi Perfil</h4>
              <p className={classes.cardCategoryWhite}>
                  <Button startIcon={<Lock />}
                  onClick={(e) => setState({ ...state, showModal: true })} 
                  color="warning">Cambiar contraseña</Button>
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    labelText="Email"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                    value: state?.data?.user?.email
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
              {state.data?.hasOwnProperty("profile") &&
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={state.data.profile?.imgUrl ? state.data.profile?.imgUrl : avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>Nombre: {state.data.profile?.name} {state.data.profile?.lastName}</h4>
                <h4 className={classes.cardTitle}>Telefono: {state.data.profile?.phone}</h4>
            </CardBody>
          </Card>
        </GridItem>
      }
      </GridContainer>
      {state.showModal &&
                    <Dialog open={state.showModal} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setState({...state, showModal: false})} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          Cambiar Contraseña
                        </Typography>
                      </Toolbar>
                    </AppBar>
                        <DialogContent>
                         <List>
                             <ListItem className={classes.listItem}>                                    
                                <FormControlLabel
                                value={state.data?.user?.email}
                                disabled
                                label="Email:"
                                labelPlacement="top"
                                control={<TextField  className={classes.textField} />}
                                    />
                             </ListItem>
                             <ListItem className={classes.listItem}>
                                <FormControlLabel
                                value={state.selected?.name}
                                onChange={(e)=> setState({...state, data: {...state.data, oldpassword: e.target.value}})}
                                label="Contraseña actual:"
                                labelPlacement="top"
                                control={<TextField type="password" className={classes.textField} />}
                                />
                             </ListItem>
                             <ListItem className={classes.listItem}>
                                <FormControlLabel
                                value={state.selected?.name}
                                onChange={(e)=> setState({...state, data: {...state.data, password: e.target.value}})}
                                label="Nueva Contraseña:"
                                labelPlacement="top"
                                control={<TextField type="password" className={classes.textField} />}
                                    />
                             </ListItem>
                             <ListItem className={classes.listItem}>
                                <FormControlLabel
                                value={state.selected?.name}
                                onChange={(e)=> setState({...state, data: {...state.data, confirmpassword: e.target.value}})}
                                label="Confirmar Nueva Contraseña:"
                                labelPlacement="top"
                                control={<TextField type="password" className={classes.textField} />}
                                    />
                             </ListItem>
                             <ListItem className={classes.listItem}>
                                <Button color="primary" autoFocus onClick={()=> save({...state.data})}>
                                Guardar
                                </Button>
                             </ListItem>
                         </List>
                        </DialogContent>
                  </Dialog>
                }
      
    </div>
  );
}
