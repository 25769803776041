import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Button,Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, Checkbox } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { toast } from "react-toastify";
import DriversApi from "api/Drivers";
import Logo from "assets/img/logo512.png"
// import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker
} from "react-google-maps";
import { Home } from "@material-ui/icons";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  listItem:{
      justifyContent: "center"
  },
  textField: {
    width: 400, 
    alignContent: "center", 
    alignSelf: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const mapStyles = {
  width: '100%',
  height: '100%'
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const initialState = {
    showModal: false,
    data: null,
    selectedData: [],
    modalTitle: "",
    selected: {
        name: ""
    },
    showAcceptModal: false,
    filterText: "",
    isLoading: true, 
    interval: null
};
class HomeComponent extends React.Component {
    state = {...initialState};
    interval = null;
    getDrivers = async () =>{
        try {
            const response = await DriversApi.getAllActiveDrivers();
            const filtered = response.data.filter(x => x.lastKnownLocation != null);
            const newLocations = this.state.selectedData.map(x => {
              const found = filtered.find(y => y.idDriverProfile === x.idDriverProfile);
              if(found){
                return found;
              }
              return x;
            });
            this.setState({ ...this.state, isLoading: false, data: [...filtered], selectedData: [...newLocations] });
            if(response.data.length === 0){
              toast.warn('No hay conductores activos.');
            }
        } catch (e) {
            toast.warn('No hay conductores activos.');
        }
    }

    componentWillUnmount(){
      clearInterval(this.interval);
    }

    componentDidMount() {
        const el = document.getElementById("card-container-body");
        el.scrollTo(0, 0);
      
        this.getDrivers();
       this.interval = setInterval(async () => {
            
            this.getDrivers();
        },30000);
    }

    render(){   
      return (
        <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Listado de conductores activos.</h4>
            </CardHeader>
            <CardBody style={{maxHeight: "25vh", overflowY: "scroll"}}>
                <List>
                {this.state.data && this.state.data.map((d, ix) => {
                      return (
                          <ListItem key={d.idDriverProfile}>
                              <ListItemText  style={{maxWidth: 50}}>
                                  <ListItemIcon>
                                      <Checkbox 
                                      checked={this.state.selectedData.some(x => x.idDriverProfile == d.idDriverProfile)} 
                                      onClick={() => {
                                        if(this.state.selectedData.some(x => x.idDriverProfile == d.idDriverProfile)){
                                          const arr = this.state.selectedData.filter(x => x.idDriverProfile !== d.idDriverProfile);
                                          this.setState({...this.state, selectedData: [...arr]});
                                        }else{
                                          this.setState({...this.state, selectedData: [...this.state.selectedData, d]});
                                        }
                                      }} />
                                  </ListItemIcon>
                              </ListItemText>
                              <ListItemText>{1+ix}. {d.name} {d.lastName}</ListItemText>
                          </ListItem>
                      )
                  })
                  }
                </List>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Mapa de conductores activos.</h4>
            </CardHeader>
            <CardBody style={{maxHeight: "60vh", overflowY: "scroll"}}>
                {
                (!this.state.isLoading) && (<MapHomeComponent data={this.state.selectedData}></MapHomeComponent>)
                  }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </>
    );
    }
}


const MapHomeComponent = (props) => (<><MapComponent    
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj32XEEWbpWreSa6kiO3M4xeqAHj9j-rQ&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `400px` }} />}
    mapElement={<div style={{ height: `100%` }} />} 
    {...props}
  /></>);

  const MapComponent = withScriptjs(withGoogleMap((props) =>{
      const { data } = props;
      const iconMarker = new window.google.maps.MarkerImage(
        Logo,
        null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new window.google.maps.Size(32, 32)
    );
    return(<GoogleMap
        defaultZoom={15}
        defaultCenter={(Array.isArray(data) && data.length > 0)
            ? { lat: data[0].lastKnownLocation.latitude, lng: data[0].lastKnownLocation.longitude } :
            { lat: 29.075675, lng: -110.960041 }}
    >
        {Array.isArray(data) && data.map((d, ix) => {
                return (                                    
                    <Marker
                    icon={iconMarker}
                    key={`ix-${ix}`}
                    title={`${d.name} ${d.lastName}`}
                    position={{ lat: d.lastKnownLocation.latitude, lng: d.lastKnownLocation.longitude }} />
                )
            })}
    </GoogleMap>)
  }));


export default withStyles(styles)(HomeComponent);