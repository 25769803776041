import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker
} from "react-google-maps";
import socketIOClient from 'socket.io-client';
import { socketUrl } from '../../constants';


const initialState = {
    locations: []
};

class Form extends React.Component {

    state = { ...initialState };

    socket = socketIOClient(socketUrl);

    cancel = () => {
        this.setState({ ...initialState }, this.props.cancel());
    }

    componentDidMount() {
        var room = `driver-${this.props.driverId}`;
        this.socket.on('connect', () => {
            this.socket.emit('room', room);
        })

        this.socket.on("message", data => {
            this.setState({
                locations: [...this.state.locations, {
                    lat: data.location.latitude,
                    lng: data.location.longitude
                }]
            }, () => this.getRoute());
        })
    }

    componentWillUnmount() {
        this.socket.removeAllListeners();
        this.socket.close();
    }

    getWaypoints = () => {
        if (this.state.locations.length > 10) {
            const points = [...this.state.locations];
            let arr = [];
            arr.push({ location: points[0] });
            for (let i = 1; i > 5; i++) {
                arr.push({ location: points[i] });
            }

            arr.push({ location: points[Math.floor(points.length / 2)] });

            for (let i = points.length - 5; i > points.length; i++) {
                arr.push({ location: points[i] });
            }
            return arr;

        } else {
            return this.state.locations.map(x => ({ location: x }));
        }
    }

    getRoute = async () => {
        const props = this.state;
        const DirectionsService = new window.google.maps.DirectionsService();
        DirectionsService.route({
            waypoints: this.getWaypoints(),
            origin: new window.google.maps.LatLng(props.locations[0].lat, props.locations[0].lng),
            destination: new window.google.maps.LatLng(props.locations[props.locations.length - 1].lat, props.locations[props.locations.length - 1].lng),
            travelMode: window.google.maps.TravelMode.DRIVING,
        }, (result, status) => {
            if (status === "OK") {
                this.setState({
                    ...this.state,
                    directions: result,
                });
            } else {
                console.error(`error fetching directions ${result}`);

            }
        });
    }

    render() {
        return (
            <div className="columns">
                <div className="column is-full">
                    <GoogleMap
                        defaultZoom={15}
                        defaultCenter={(Array.isArray(this.state.locations) && this.state.locations.length > 0)
                            ? this.state.locations[0] :
                            { lat: 29.075675, lng: -110.960041 }}
                    >
                        {this.state.directions && (<>
                            <DirectionsRenderer
                                options={{
                                    suppressMarkers: true
                                }}
                                directions={this.state.directions} />
                                {
                                   this.state.locations.length > 0 && (<>
                                   <Marker
                                        label="A"
                                        position={{ lat: this.state.locations[0].lat, lng: this.state.locations[0].lng }} />
                                    <Marker
                                        label="B"
                                        position={{ lat: this.state.locations[this.state.locations.length - 1].lat, lng: this.state.locations[this.state.locations.length - 1].lng }} />
                            </>) 
                            
                                }
                        </>)}
                    </GoogleMap>
                </div>
            </div>
        )
    }
}

export default withScriptjs(withGoogleMap(Form));