import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker,
    Polyline 
} from "react-google-maps";


const initialState = {
    selected: null,
    directions: [],
    defaultCenter: null
};

class Form extends React.Component {

    state = { ...initialState };

    cancel = () => {
        this.setState({ ...initialState }, this.props.cancel());
    }

    componentDidMount() {
        const props = this.props;
        if (Array.isArray(props.selected) && props.selected.length > 0) {
            this.getRoute();
        }
    }

    getWaypoints = (locations) => {
        
        return locations.map(x => ({ lat: x.latitude, lng: x.longitude }));
    }

    getRoute = async () => {
        const props = this.props;
        let arr = [];
        props.selected.forEach(x => {
            arr.push(this.getWaypoints(x.locations));
        });
        this.setState({...this.state, directions: arr})
    }

    getDirectionRoute = (direction, ix) => {
        return (
            <React.Fragment key={`map-${ix}`}>
                <Polyline
                path={direction}
                geodesic={true}
                    options={{
                        suppressMarkers: true
                    }} />
                <Marker
                    label={`${ix + 1}-A`}
                    position={direction[0]} />
                <Marker
                    label={`${ix + 1}-B`}
                    position={direction[direction.length - 1]} />
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className="columns">
                <div className="column is-full">
                    <GoogleMap
                        defaultZoom={15}
                        defaultCenter={this.state.defaultCenter !== null ? this.state.defaultCenter :
                            { lat: 29.075675, lng: -110.960041 }}
                    >
                        {this.state.directions.map((x, ix) => this.getDirectionRoute(x, ix))}
                    </GoogleMap>
                </div>
            </div>
        )
    }
}

export default withScriptjs(withGoogleMap(Form));