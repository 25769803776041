import React, { useState } from 'react'
import logo from 'assets/img/logo_color.png';
import LoginApi from "../../api/login";
import { toast } from 'react-toastify';
import Footer from '../Footer/Footer';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Card, FormControlLabel, CardActionArea, CardMedia, CardContent, Button, List, ListItem } from '@material-ui/core';
import "./Login.css";

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        }
    }

    login = async () => {
        try {
            const responseLogin = await LoginApi.login(this.state.email, this.state.password);
            console.log("response")
            console.log(responseLogin)
            localStorage.setItem('token', responseLogin.data.access_token);
            // localStorage.setItem('profile', responseLogin.data.profile);
            localStorage.setItem('is_admin', responseLogin.data.user.is_admin);
            localStorage.setItem('id', responseLogin.data.user.id);
            // localStorage.setItem('idClient', responseLogin.data.idClientProfile);
            // localStorage.setItem('idDriver', responseLogin.data.idDriverProfile);
            localStorage.setItem('email', responseLogin.data.user.email);
            // const responseProfile = await LoginApi.getUser(responseLogin.data.user.id);
            localStorage.setItem('ProfileUser', JSON.stringify(responseLogin.data.user));
            window.location.reload();
        } catch (e) {
        }
    }

    render() {
        return (
            <div style={{
                display: "flex",
                alignContent: "center", 
                flexDirection: "column", 
                marginTop: 150, 
                  overflow: "hide"}}>
                
        <Card style={{width: "75vw", alignSelf:"center", backgroundColor: "#ddd", boxShadow: "5px 5px 5px black"}}>
            <CardActionArea>
        <CardMedia
          title="Zeve"
          style={{textAlign: "center"}}
        ><img src={logo}></img>
        </CardMedia>
        <CardContent>
            <List style={{justifyContent: "center", alignItems:"center"}}>
                <ListItem  style={{justifyContent: "center", alignItems:"center"}}>
        <FormControlLabel 
        style={{width: 250}}
        label="Correo"
        labelPlacement="top"
        control={<TextField 
            fullWidth
            type={'email'}
            value={this.state.email}
            onChange={(e) => {
                let state = { ...this.state };
                state.email = e.currentTarget.value;
                this.setState(state);
            }}/>}/>

                </ListItem>
                <ListItem  style={{justifyContent: "center", alignItems:"center"}}>
        <FormControlLabel 
        style={{width: 250}}
        label="Contraseña"
        labelPlacement="top"
        control={<TextField 
            fullWidth
            value={this.state.password}
            onChange={(e) => {
                let state = { ...this.state };
                state.password = e.currentTarget.value;
                this.setState(state);
            }}
        type="password"/>}/>

                </ListItem>
                <ListItem style={{justifyContent: "center", alignItems:"center"}}>
        <Button
            className="is-success button"
            disabled={!this.state.password || !this.state.email}
            onClick={() => this.login()}
        >
            Iniciar sesión
        </Button>

                </ListItem>
            </List>
        
        </CardContent>
      </CardActionArea> 

      </Card>
            </div>
        )
    }
}