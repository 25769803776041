import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText,
     ListItemSecondaryAction, IconButton,
     Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
     Button,Slide,AppBar,Toolbar, Typography,FormControlLabel,TextField, Checkbox, Input } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Send from "@material-ui/icons/Send";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { toast } from "react-toastify";
import DriversApi from "api/Drivers";
import stylesInput from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomButton from "components/CustomButtons/Button.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: "50",
    flex: 1,
  },
  listItem:{
      justifyContent: "center"
  },
  textField: {
    width: 400, 
    alignContent: "center", 
    alignSelf: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const initialState = {
    showModal: false,
    data: null,
    selectedData: [],
    modalTitle: "",
    selected: {
        name: ""
    },
    message: "",
    title: "",
    showAcceptModal: false,
    filterText: ""
};
class HomeComponent extends React.Component {
    state = {...initialState};
    getCarTypes = async () =>{
        try {
            const response = await DriversApi.getAllNotificableDrivers();
            this.setState({ ...this.state, data: response.data.users });
        } catch (e) {
            toast.warn('No hay conductores activos.');
        }
    }
    
    componentDidMount() {
        const el = document.getElementById("card-container-body");
        el.scrollTo(0, 0);
      
        this.getCarTypes();
    }

    render(){   
      return (
        <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Listado de conductores con notificaciones habilitadas.</h4>
            </CardHeader>
            <CardBody style={{maxHeight: "25vh", overflowY: "scroll"}}>
                <List>
                {this.state.data && this.state.data.map((d, ix) => {
                      return (
                          <ListItem key={d.idUser}>
                              <ListItemText  style={{maxWidth: 50}}>
                                  <ListItemIcon>
                                      <Checkbox 
                                      checked={this.state.selectedData.some(x => x.idUser == d.idUser)} 
                                      onClick={() => {
                                        if(this.state.selectedData.some(x => x.idUser == d.idUser)){
                                          const arr = this.state.selectedData.filter(x => x.idUser !== d.idUser);
                                          this.setState({...this.state, selectedData: [...arr]});
                                        }else{
                                          this.setState({...this.state, selectedData: [...this.state.selectedData, d]});
                                        }
                                      }} />
                                  </ListItemIcon>
                              </ListItemText>
                              <ListItemText>{1+ix}. {d.profile.name} {d.profile.lastName}</ListItemText>
                          </ListItem>
                      )
                  })
                  }
                </List>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Enviar notificación.</h4>
            </CardHeader>
            <CardBody style={{maxHeight: "60vh", overflowY: "scroll"}}>
                
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Titulo"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    multiline
                    inputProps={{
                      disabled: false,
                    value: this.state.title,
                    fullWidth: true,
                    onChange: (text) => {
                        this.setState({...this.state, title: text.target.value})
                    }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Mensaje"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    multiline
                    inputProps={{
                      disabled: false,
                    value: this.state.message,
                    fullWidth: true,
                    onChange: (text) => {
                        this.setState({...this.state, message: text.target.value})
                    }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomButton color="info" style={{marginTop: 40}}
                  onClick={async()=>{
                      try{
                        if(this.state.selectedData.length> 0 && this.state.title != "" && this.state.message != ""){
                            const tokens = this.state.selectedData.map(x => x.firebase_token);
                            const users = this.state.selectedData.map(x => x.idUser);
                            await DriversApi.sendNotificationDrivers({
                                title: this.state.title,
                                message: this.state.message,
                                registrationTokens: tokens,
                                users: users
                            });
                            toast.success('Notificación enviada.');
                        }else{
                            toast.warn('Asegurate de llenar todos los campos.');
                        }
                      }catch(e){

                      }
                  }}><Send style={{marginRight: 5}} /> Enviar </CustomButton>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </>
    );
    }
}



export default withStyles(styles)(HomeComponent);