/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import React from "react";
import Person from "@material-ui/icons/Person";
import Car from "@material-ui/icons/DirectionsCar";
import Commute from "@material-ui/icons/Commute";
import LocalTaxi from "@material-ui/icons/LocalTaxi";
import HomeIcon from "@material-ui/icons/Home";
import NotificationIcon from "@material-ui/icons/Notifications";
import Group from "@material-ui/icons/Group";
// core components/views for Admin layout
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import TipoVehiculo from "views/TipoVehiculo/tipoVehiculo";
import UsoVehiculo from "views/UsoVehiculo/usoVehiculo";
import Home from "views/Home/Home";
import Notification from "views/Notification/notification";
import Perfil from "views/Perfil/perfil";
import Clients from "views/Client/client";
import Conductores from "views/Conductores/conductores";
import ClienteConductor from "views/ClienteConductor/clienteConductor";
import DetalleConductores from "views/DetalleConductores/detalleConductores";
import DetalleClientes from "views/ClienteDetalle/clienteDetalle";

const dashboardRoutes = [
  {
    path: "/Home",
    name: "Conductores Activos",
    rtlName: "",
    icon: NotificationIcon,
    component: Home,
    layout: "/admin",
    adminRoute: false
  },
  {
    path: "/Notificaciones",
    name: "Notificaciones",
    rtlName: "",
    icon: HomeIcon,
    component: Notification,
    layout: "/admin",
    adminRoute: true
  },
  {
    path: "/perfil",
    name: "Perfil",
    rtlName: "",
    icon: Person,
    component: Perfil,
    layout: "/admin",
    adminRoute: false
  },
  {
    path: "/conductores/detalle",
    name: "Conductores Detalle",
    component: DetalleConductores,
    layout: "/admin",
    hidden: true,
    adminRoute: true
  },
  {
    path: "/clientes/detalle",
    name: "Clientes Detalle",
    component: DetalleClientes,
    layout: "/admin",
    hidden: true,
    adminRoute: true
  },
  {
    path: "/clienteconductor/detalle",
    name: "Conductores Detalle",
    component: DetalleConductores,
    layout: "/admin",
    hidden: true,
    adminRoute: false
  },
  {
    path: "/Conductores",
    name: "Conductores",
    rtlName: "",
    icon: Car,
    component: Conductores,
    layout: "/admin",
    adminRoute: false
  },
  {
    path: "/clienteconductor",
    name: "Conductores",
    rtlName: "",
    icon: Car,
    component: ClienteConductor,
    layout: "/admin",
    adminRoute: false,
    onlyClient: true
  },
  {
    path: "/clientes",
    name: "Clientes",
    rtlName: "",
    icon: Group,
    component: Clients,
    layout: "/admin",
    adminRoute: true
  },
  {
    path: "/tipovehiculo",
    name: "Tipo Vehículo",
    rtlName: "",
    icon: Commute,
    component: TipoVehiculo,
    layout: "/admin",
    adminRoute: true
  },
  {
    path: "/usovehiculo",
    name: "Uso de Vehículos",
    rtlName: "",
    icon: LocalTaxi,
    component: UsoVehiculo,
    layout: "/admin",
    adminRoute: true
  }
];

export default dashboardRoutes;
